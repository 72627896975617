import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <p className="footer-copyright">
        © 2024 Sarom Logistics & Unloading Ltd. All Rights Reserved.
      </p>
      <hr className="footer-divider" />
      <div className="footer-links" />
    </div>
  );
};

export default Footer;
